
// import "./css/landing.css";
import "./css/globals.css";
import "./css/styleguide.css";


function LandingPage() {

  return (
    <div class="container-center-horizontal">
      <div class="landing screen">
        <div class="overlap-group7">
          <img class="bg" src="img/bg-1@2x.png" />
          <div class="group-1000002054">
            <div class="container">
              <div class="heading inter-normal-white-36px">
                <span class="inter-normal-white-36px">License our technology</span>
              </div>
              <div class="supporting-text-1">
                <span
                  >Are you a publisher whose users want to search live videos, audio or UGC by keyword? Are you looking
                  to monetize spoken words or site traffic in real-time?</span
                >
              </div>
            </div>
            <div class="button-base">
              <div class="text-1 inter-medium-white-16px"><span class="inter-medium-white-16px">Contact us</span></div>
            </div>
          </div>
          <div class="container-1">
            <div class="tab">
              <img class="tab-line" src="img/tab-line-4@2x.png" />
              <div class="content">
                <div class="text inter-medium-white-20px"><span class="inter-medium-white-20px">Find Voices</span></div>
                <p class="supporting-text inter-normal-nobel-16px">
                  <span class="inter-normal-nobel-16px"
                    >nēdl, as in &#39;the needle in the haystack,&#39; lets users search live audio as it&#39;s being
                    spoken.</span
                  >
                </p>
              </div>
            </div>
            <div class="tab-1">
              <img class="tab-line" src="img/tab-line-3@2x.png" />
              <div class="content">
                <div class="text inter-medium-white-20px">
                  <span class="inter-medium-white-20px">Live audio search</span>
                </div>
                <p class="supporting-text-2 inter-normal-nobel-16px">
                  <span class="inter-normal-nobel-16px"
                    >nēdl owns the US and multinational patent on real-time keyword search of live audio broadcasts (TV,
                    Radio, Social Media, Streaming) and additional related intellectual property</span
                  >
                </p>
              </div>
            </div>
            <div class="tab-2">
              <img class="tab-line" src="img/tab-line-3@2x.png" />
              <div class="content">
                <div class="text inter-medium-white-20px">
                  <span class="inter-medium-white-20px">Real-time brand safety</span>
                </div>
                <p class="supporting-text inter-normal-nobel-16px">
                  <span class="inter-normal-nobel-16px"
                    >nēdl&#39;s live transcription and real-time search means that brands can be assured that the places
                    they advertise meet or exceed their content requirements.</span
                  >
                </p>
              </div>
            </div>
          </div>
          <img class="group-228" src="img/group-228-1@2x.png" />
          <img class="line-15" src="img/line-15-1@2x.png" />
          <div class="heading-1">
            <span
              >Social Media companies control which Voices get heard &amp; which don&#39;t.<br />We&#39;re here to
              change that.</span
            >
          </div>
          <div class="group-223">
            <div class="ellipse-container">
              <div class="ellipse-33 border-2px-sunset-orange"></div>
              <div class="ellipse-34 border-2px-sunset-orange"></div>
              <div class="ellipse-35 border-2px-sunset-orange"></div>
            </div>
          </div>
          <div class="group-224">
            <div class="ellipse-container">
              <div class="ellipse-33-1 border-2px-sunset-orange"></div>
              <div class="ellipse-34-1 border-2px-sunset-orange"></div>
              <div class="ellipse-35-1 border-2px-sunset-orange"></div>
            </div>
          </div>
          <img class="nedl-white-1" src="img/nedl-white-1-1@2x.png" />
          <img class="nedl-white-2" src="img/nedl-white-1-1@2x.png" />
          <div class="button-base-1">
            <div class="text-2 inter-medium-white-16px">
              <span class="inter-medium-white-16px">Investor relations</span>
            </div>
          </div>
          <div class="frame-225">
            <div class="group-224-1">
              <img class="ant-designcaret-down-filled" src="img/ant-design-caret-down-filled-1@2x.png" />
              <div class="products inter-medium-white-20px"><span class="inter-medium-white-20px">Products</span></div>
            </div>
            <div class="about inter-medium-white-20px"><span class="inter-medium-white-20px">About</span></div>
          </div>
          <div class="group-225">
            <div class="flex-row">
              <img class="line-15-1" src="img/line-15-2@2x.png" />
              <div class="as-seen-on"><span>As seen on</span></div>
              <img class="line-14" src="img/line-15-2@2x.png" />
            </div>
            <div class="flex-col">
              <div class="group-220">
                <img class="forbes" src="img/forbes-1@2x.png" />
                <img class="vb-logo" src="img/vb-logo-1@2x.png" />
                <img class="group-219" src="img/group-219-1@2x.png" />
                <img class="union" src="img/union-1@2x.png" />
              </div>
              <img class="line-15-2" src="img/line-15-3@2x.png" />
            </div>
          </div>
          <div class="welcome-to-the-world">
            <span>Welcome to the World’s most powerful Live audio<br />search engine</span>
          </div>
          <div class="frame-1000002057"></div>
          <img class="dev-1" src="img/dev-1-1@2x.png" />
          <img class="group-227" src="img/group-227-1@2x.png" />
          <div class="copyright"><span>© Copyright 2022 nēdl</span></div>
          <div class="features inter-normal-white-48px"><span class="inter-normal-white-48px">Features</span></div>
          <img class="dev-2" src="img/dev-2-1@2x.png" />
          <div class="group-1000002053">
            <img class="group-1000002056" src="img/group-1000002056-1@1x.png" />
            <div class="container-2">
              <div class="tab-3">
                <img class="tab-line" src="img/tab-line-4@2x.png" />
                <div class="content">
                  <div class="text inter-medium-white-20px">
                    <span class="inter-medium-white-20px">Reach your entire audience</span>
                  </div>
                  <p class="supporting-text inter-normal-nobel-16px">
                    <span class="inter-normal-nobel-16px"
                      >Your existing audience can not only listen via the web, but sign up for Reminder notifications
                      whenever you go Live. Tap the mic and they’ll all get an SMS alert.</span
                    >
                  </p>
                </div>
              </div>
              <div class="tab-4">
                <img class="tab-line-1" src="img/tab-line-6@2x.png" />
                <div class="content-1">
                  <div class="text inter-medium-white-20px">
                    <span class="inter-medium-white-20px">Monetize your words as you speak</span>
                  </div>
                  <p class="supporting-text-3 inter-normal-nobel-16px">
                    <span class="inter-normal-nobel-16px"
                      >Our patent on the Real-time Keyword Search of live audio extends beyond discovery. We’ve made it
                      so that advertisers can secretly bid on your words. Speak to your audience authentically and we’ll
                      bring you instant opportunities from the world’s biggest advertisers.</span
                    >
                  </p>
                </div>
              </div>
              <div class="tab-5">
                <img class="tab-line-1" src="img/tab-line-6@2x.png" />
                <div class="content-1">
                  <div class="text inter-medium-white-20px">
                    <span class="inter-medium-white-20px">Experience full control</span>
                  </div>
                  <p class="supporting-text-4 inter-normal-nobel-16px">
                    <span class="inter-normal-nobel-16px"
                      >It’s your own live call-in show to create. No Program Director to answer to. No new social media
                      jargon or language to use. Just an old fasioned meaninful connection with your audience that you
                      control from top to bottom.</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="the-people-have-spoken inter-normal-white-48px">
            <span class="inter-normal-white-48px">The People have spoken</span>
          </div>
          <div class="group-231">
            <div class="group-230">
              <div class="overlap-group5">
                <h1 class="text-1-1"><span>4.6</span></h1>
                <div class="app-ratings inter-medium-white-36px">
                  <span class="inter-medium-white-36px">App ratings</span>
                </div>
              </div>
              <div class="out-of-5 inter-medium-white-36px"><span class="inter-medium-white-36px">out of 5</span></div>
            </div>
            <div class="overlap-group6">
              <div class="quote-and-attribution">
                <div class="quote-and-stars">
                  <div class="stars">
                    <div class="star-icon-1">
                      <div class="star-container">
                        <img class="star-background" src="img/star-background-5@2x.png" />
                        <div class="star"><img class="star-1" src="img/star-5@2x.png" /></div>
                      </div>
                    </div>
                    <div class="star-icon">
                      <div class="star-container">
                        <img class="star-background" src="img/star-background-5@2x.png" />
                        <div class="star"><img class="star-1" src="img/star-5@2x.png" /></div>
                      </div>
                    </div>
                    <div class="star-icon">
                      <div class="star-container">
                        <img class="star-background" src="img/star-background-5@2x.png" />
                        <div class="star"><img class="star-1" src="img/star-5@2x.png" /></div>
                      </div>
                    </div>
                    <div class="star-icon">
                      <div class="star-container">
                        <img class="star-background" src="img/star-background-5@2x.png" />
                        <div class="star"><img class="star-1" src="img/star-5@2x.png" /></div>
                      </div>
                    </div>
                    <div class="star-icon">
                      <div class="star-container">
                        <img class="star-background" src="img/star-background-5@2x.png" />
                        <div class="star"><img class="star-1" src="img/star-5@2x.png" /></div>
                      </div>
                    </div>
                  </div>
                  <div class="quote">
                    <span
                      >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tortor sit purus arcu ultrices nibh
                      tellus eu.</span
                    >
                  </div>
                </div>
                <div class="text-3 inter-medium-white-18px">
                  <span class="inter-medium-white-18px">— John Doe , MM/DD/YYY</span>
                </div>
              </div>
              <img class="arrows" src="img/arrows-1@2x.png" />
            </div>
          </div>
          <img class="vector" src="img/vector-1@2x.png" />
          <img class="vector-1" src="img/vector-2@2x.png" />
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
