import { Routes, Route } from "react-router-dom";

import ProfilePage from "./pages/ProfilePage/ProfilePage";
import LandingPage from "./pages/LandingPage/LandingPage.page";

// import AgoraTestPage from "./pages/AgoraTestPage";
import "./custom.scss";
import StreamProvider from "./contexts/StreamContext";
import ProfilePageV2 from "./pages/ProfilePage/ProfilePageV2";

// TODO: move routes a config object

function App() {
  return (
    <StreamProvider>
      <div className="App">
        <Routes>
          <Route path="/:channelName" element={<ProfilePageV2 />} />
          <Route path="/:channelName/off-air" element={<ProfilePageV2 />} />
          <Route path="/" exact element={<LandingPage />} />
          {/* <Route path="/test" element={<AgoraTestPage />} /> */}
        </Routes>
      </div>
    </StreamProvider>
  );
}

export default App;
