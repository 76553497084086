import { createApi } from "@reduxjs/toolkit/query/react";
import { publicBaseQuery } from "./baseQueries";
export const agoraAPI = createApi({
  reducerPath: "agoraAPI",
  baseQuery: publicBaseQuery,
  endpoints: (build) => ({
    getStreamToken: build.query({
      query: ({ channelName, guestId }) => ({
        url: `/agora/streams/${channelName}/guest/rtc-token/?guestId=${guestId}`,
      }),
    }),
    getStream: build.query({
      query: (channelName) => ({
        url: `/agora/streams/${channelName}/`
      })
    })
  }),
});

export const { useGetStreamTokenQuery, useGetStreamQuery } = agoraAPI;
