import React from "react";
import "./comment.scss";
import moment from "moment";

function Comment({ comment }) {
  return (
    <>
      {comment.body && comment.user && (
        <div className="d-flex justify-content-start my-2">
          <img
            src={comment.user.avatarUrl || "/assets/img/pro-pic-2.jpg"}
            alt="user profile"
            width={40}
            height={40}
            style={{ borderRadius: "50%", objectFit: "cover" }}
          />
          <div className="mx-2 p-2 w-100 position-relative">
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#c4c5c6",
              }}
            >
              {comment.user.name}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#fff",
              }}
            >
              {comment.body}
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#C4C5C6",
                position: "absolute",
                right: "0.5rem",
                top: "0.5rem",
              }}
            >
              {moment.unix(comment.timestamp).fromNow()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Comment;
