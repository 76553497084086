import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Comment from "../../components/Comment";
import SubscriptionModal from "../../components/Modal/SubscriptionModal";
import MuteUnmute from "../../components/MuteUnmute/MuteUnmute";
import { useStream } from "../../hooks/streamHooks";
import { useGetStreamQuery } from "../../services/agoraService";
import "./profile-page.scss";

const ProfilePageV2 = () => {
  const { channelName } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  const commentEndRef = useRef(null);
  const transcriptEndRef = useRef(null);

  const { commentStream } = useStream();
  const { comments, transcripts, changeChannelName } = commentStream;

  const { data: stream } = useGetStreamQuery(channelName, {
    pollingInterval: 1000,
  });

  useEffect(() => {
    changeChannelName(channelName);
  }, [channelName]);

  const isOnAir = stream?.data?.Status === "ONLINE";

  const handleGetRemainder = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    commentEndRef.current?.scrollIntoView(false);
  }, [comments]);

  useEffect(() => {
    transcriptEndRef.current?.scrollIntoView(false);
  }, [transcripts]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${stream?.data?.AvatarUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={`background-gradient vh-100 ${
          isOpen ? "background-blur" : ""
        }`}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="w-75 vh-100 d-flex flex-column justify-content-between align-items-start py-4 px-5">
            <div className="d-flex flex-column gap-4 p-2">
              <div className="p-2 d-flex gap-4">
                <img
                  src="/assets/img/logo.png"
                  alt="logo"
                  width={96}
                  height={47}
                />

                <div
                  style={{ flex: 1, backgroundColor: "#D5D5D52B" }}
                  className="d-none d-sm-flex p-2 justify-content-around rounded-pill"
                >
                  <button className="bg-transparent border-0">
                    <img src="/assets/icon/search-icon.svg" alt="search icon" />
                  </button>
                  <input
                    type="text"
                    id="search"
                    className="white-placeholder bg-transparent mx-2 border-0  w-100"
                    style={{
                      outline: "none",
                      color: "#FFFFFF",
                    }}
                    placeholder="Search an artist"
                  />
                </div>

                <div className="d-flex gap-2">
                  <a href="https://apps.apple.com/app/n%C4%93dl-ai-tuner-for-live-radio/id1367332698">
                    <img
                      src="/assets/img/app-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.nedl.universal">
                    <img
                      src="/assets/img/play-store.png"
                      alt=""
                      width={134}
                      height={44}
                    />
                  </a>
                </div>
              </div>
              <h5 className="fs-4 fw-normal" style={{ color: "#FFFFFFBA" }}>
                nedl ( as in ‘ the needle in the haystack’) is a live audio
                platform <br />
                where you can Find Voices & Be Heard.
              </h5>
            </div>

            <div className="d-flex flex-column gap-4 align-items-start">
              <div className="d-flex gap-2 align-items-center ">
                <div
                  className=" rounded-circle"
                  style={{
                    width: "32px",
                    height: "32px",
                    backgroundColor: isOnAir
                      ? "#EE4044"
                      : "rgba(209, 209, 209, 1)",
                  }}
                />
                <p
                  className="text-center m-0"
                  style={{
                    color: isOnAir ? "#fff" : "rgba(209, 209, 209, 1)",
                    fontSize: "32px",
                    lineHeight: "20px",
                    fontWeight: 400,
                  }}
                >
                  {isOnAir ? "On-Air" : "Off-Air"}
                </p>
              </div>
              <h1
                className="w-75 text-white"
                style={{
                  fontSize: "96px",
                  lineHeight: "96px",
                  fontWeight: 600,
                }}
              >
                {stream?.data?.Name}{" "}
                <span
                  style={{
                    fontSize: "36px",
                    lineHeight: "24px",
                    fontWeight: 400,
                  }}
                >
                  {stream?.data?.Location || "No Location"}
                </span>
              </h1>
              <button
                className="bg-transparent border-white rounded-pill text-white py-3 px-5"
                style={{
                  fontSize: "20px",
                  lineHeight: "16px",
                  fontWeight: 700,
                  outline: "none",
                }}
                onClick={handleGetRemainder}
              >
                Get A Reminder
                <img
                  src="/assets/icon/remainder.svg"
                  alt="remainder"
                  width={20}
                  height={20}
                  style={{ marginLeft: "10px" }}
                />
              </button>
            </div>
          </div>
          {isOnAir && (
            <div
              className="p-4 vh-100 w-50 d-none d-md-flex flex-column pt-4 gap-4"
              style={{
                backgroundColor: "#414141CF",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <MuteUnmute
                isOnAir={isOnAir}
                setIsOnAir={() => {}}
                streamStatus={stream?.data.Status}
              />
              <div className="transcription">
                {transcripts.length === 0 ? (
                  <Fragment>
                    Please wait for a while for transcriptions to appear...
                  </Fragment>
                ) : (
                  <div>
                    {transcripts.map((transcript, index) => (
                      <Fragment key={index}>{transcript.body} &nbsp;</Fragment>
                    ))}
                    <div ref={transcriptEndRef} />
                  </div>
                )}
              </div>
              <div
                className="mt-0 h-100"
                style={{
                  maxHeight: "100%",
                  overflowY: "scroll",
                }}
              >
                {comments.map((comment, index) => (
                  <div key={index}>
                    <Comment comment={comment} />
                  </div>
                ))}
                <div ref={commentEndRef} />
              </div>
            </div>
          )}
        </div>
      </div>

      <SubscriptionModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default ProfilePageV2;
