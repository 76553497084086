import React from "react";
import "./subscription.scss";

function SubscriptionModal({ isOpen, setIsOpen }) {
  //   const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="subscription-modal-container d-flex justify-content-center align-items-center">
          <div className="subscription-modal p-5 d-flex justify-content-center align-items-center flex-column">
            <div className="title px-2 mb-4">
              Enter your number to get a <br /> reminder when they go On-Air
            </div>
            <div className="form-section d-flex justify-content-center align-items-center mb-4 px-4">
              <div className="outlined-input country-drop-down mx-1 d-flex justify-content-between align-items-center">
                <img
                  src="/assets/icon/down-arrow-icon.svg"
                  alt=""
                  className="down-arrow"
                />
                <img
                  src="/assets/icon/flag-icon.svg"
                  alt="remainder"
                  width={21}
                  height={15}
                  style={{ marginLeft: "5px", marginRight: "5px" }}
                />
                <div className="zip-code">(+1)</div>
              </div>
              <input type="text" className="outlined-input phone-field mx-1" />
            </div>
            <div className="policy mb-4 mx-4">
              By entering your number you’re agreeing to our terms of services
              &amp; Privacy policy.
            </div>
            <div className="d-flex flex-row gap-3 justify-content-between">
              <div className="cancel-btn" onClick={handleCancel}>
                Cancel
              </div>
              <div className="confirm-btn">Confirm</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SubscriptionModal;
